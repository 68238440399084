import { AppColor } from "../app/AppStyles";

export enum ModalType {
    Error,
    Information,
    Success
}

export const getIconFromType = (type: ModalType) => {
    switch (type) {
        case ModalType.Error: return "x-octagon-fill";
        case ModalType.Success: return "check-circle-fill";
        default: return "info-square-fill";
    }
}

export const getColorFromType = (type: ModalType): AppColor => {
    switch (type) {
        case ModalType.Error: return "error";
        case ModalType.Success: return "success";
        default: return "secondary";
    }
}