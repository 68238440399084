import React from "react";
import { IPlanFeature } from "../../types/ApiTypes";
import Icon from "../icons/Icon";
import { AppColor } from "../../app/AppStyles";

export default function SubscriptionPlanFeature({feature}: {feature: IPlanFeature}) {
    
    if (!feature || !feature.feature) return null;

    return (
        <div className="d-flex flex-column">
            <div className="d-flex flex-row align-items-center gap-2">
                <Icon icon="check" color="success" />      
                <span>{feature.feature.name}</span> 
                {
                    feature.limit && <span>({feature.limit})</span>
                }
            </div>
            {
                feature.remarks && <span>{feature.remarks}</span>
            }
        </div>
    )
}