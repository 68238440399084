import { TicketEscalationTrigger } from "../types/ApiTypes";

export default function useTicketEscalation() {
    const getNameForTrigger = (trigger: TicketEscalationTrigger) => {
        switch (trigger) {
            case TicketEscalationTrigger.MinutesOpen: return "Minuten ohne Abschluss";
            case TicketEscalationTrigger.MinutesWithoutAssigneeMessage: return "Minuten ohne Nachricht des Bearbeiters";
            case TicketEscalationTrigger.MinutesWithoutClientResponse: return "Minuten ohne Antwort des Mandanten";
        }
    }

    return {
        getNameForTrigger
    }
}