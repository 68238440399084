import React from "react";
import { AppColor } from "../../app/AppStyles";
import { ModalType } from "../../config/ModalTypes";
import useModal from "../../hooks/useModal";
import Button from "../buttons/Button";
import Icon from "../icons/Icon";
import "./FileSelect.css";

export interface IFileSelectValidationResult {
    success: boolean, 
    validationError: string, 
    data: any
}

export interface IFileSelectProps {
    saveContent: (data: any) => void,
    resetContent: () => void,
    accept: string,
    validateInput?: (data: any) => IFileSelectValidationResult,
}

export default function FileSelect({accept, validateInput, resetContent, saveContent}: IFileSelectProps) {

    const [fileIsValid, setFileIsValid] = React.useState<boolean>(false);
    const [hasFileSelected, setHasFileSelected] = React.useState<boolean>(false);
    const [canSelect, setCanSelect] = React.useState<boolean>(false);
    const [validationError, setValidationError] = React.useState<string>("");

    const showModal = useModal();
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (!inputRef || !inputRef.current) setCanSelect(false);
        else setCanSelect(true);
    }, [inputRef.current]);

    const resetValue = () => {
        if (!inputRef || !inputRef.current) return;
        inputRef.current.value = "";
    }

    const preventDefaults = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }
    
    const handleSelectedFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        preventDefaults(e);
        if (!e || !e.target || !e.target.files || !e.target.files.length) return;
        handleFiles(e.target.files);
    }

    const handleFiles = (files: FileList) => {
        if (files.length === 0) {
            showModal({text: "Bitte eine Datei auswählen.", type: ModalType.Error});
            return;
        }

        if (files.length > 1) {
            showModal({text: "Bitte nur eine Datei auswählen.", type: ModalType.Error});
            return;
        }

        uploadFile(files[0]);
    }

    const uploadFile = (file: Blob) => {
        let reader = new FileReader()
        reader.readAsText(file)
        reader.onloadend = () => {
            setHasFileSelected(true);

            const validationResponse: IFileSelectValidationResult = validateInput ? validateInput(reader.result) : {
                data: reader.result,
                success: true,
                validationError: ""
            };

            setFileIsValid(validationResponse.success);

            if (validationResponse.success) {
                saveContent(validationResponse.data);
                return;
            } 

            resetValue();

            showModal({
                text: validationResponse.validationError,
                type: ModalType.Error
            })
            
            setValidationError(validationResponse.validationError);
        }
        reader.onerror = (ev: ProgressEvent<FileReader>) => console.log(ev);
    }

    const selectFile = async () => {
        if (!inputRef || !inputRef.current) return;
        inputRef.current.click();
    }
    
    const deselectFile = async () => {
        resetValue();
        resetContent();
        setHasFileSelected(false);
    }

    return (
        <div className="d-flex gap-2 w-100 justify-content-between">
            {
                hasFileSelected && 
                (
                    fileIsValid 
                    ? (
                        <div className="d-flex flex-row align-items-center">
                            <Icon color="success" icon="check" />
                            <span>Datei ausgewählt</span>
                        </div>            
                    )
                    : (
                        <div className="d-flex flex-row align-items-center gap-1">
                            <Icon color="error" icon="x" size={24} />
                            <div className="d-flex flex-column">
                                <span>Fehlerhafte Datei ausgewählt</span>
                                <em style={{"fontSize": "0.9em"}}>{validationError}</em>
                            </div>
                        </div>            
                    )
                )
            }
            <Button icon="folder" onClick={hasFileSelected && fileIsValid ? deselectFile : selectFile} disabled={!canSelect} color={hasFileSelected && fileIsValid ? "error" : "primary"} text={hasFileSelected && fileIsValid ? "Auswahl aufheben" : "Datei auswählen"} />
            <input ref={inputRef} name="file" accept={accept} className="file-select" type="file" onChange={handleSelectedFiles} />
        </div>
    )
}