import React from "react";
import useTicketUtil from "../../../hooks/useTicketUtil";
import { ITicket, TicketType, UserRole } from "../../../types/ApiTypes";
import UserRoleIndicator from "../../user/RoleBanner";
import Flex from "../../container/Flex";
import Typography from "../../text/Typography";
import useUserUtil from "../../../hooks/useUserUtil";
import WithLabel from "../../text/WithLabel";

export default function TicketSourceBanner({ticket, colorOnly = false}: {colorOnly?: boolean, ticket: ITicket}) {
    const {
        getTicketSourceName
    } = useTicketUtil();

    const {
        getName
    } = useUserUtil();

    if (!ticket || ticket.type === null || ticket.type === undefined) return null;

    return (
        <WithLabel label="Eröffnet" gap={0}>
            <Typography>{getTicketSourceName(ticket)} { ticket && ticket.openedBy && `von ${getName(ticket.openedBy)}`}</Typography>
        </WithLabel>
    )
}