import React from "react";
import { ITicketMessageAddAttachment } from "../../../api/ApiRequests";
import Icon from "../../icons/Icon";
import useAttachments from "../../../hooks/useAttachments";
import { getTextColorFromBackground } from "../../../util/util";
import { IFileMetadata } from "../../../types/ApiTypes";

export default function TicketChatAttachmentIcon({attachment, size = 24}: {attachment: IFileMetadata, size?: number}) {

    const {
        getIcon,
        getColor
    } = useAttachments();

    const icon = getIcon(attachment);

    return <Icon icon={icon} size={size} hexColor={getTextColorFromBackground(getColor(attachment))} />
}