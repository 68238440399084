import React from "react";
import Flex from "../container/Flex";

export interface IPermissionGroupProps {
    title: React.ReactNode,
    isHeader?: boolean,
    create: React.ReactNode,
    read: React.ReactNode,
    update: React.ReactNode,
    del: React.ReactNode,
}

export default function PermissionContainer({isHeader, create, del, read, title, update }: IPermissionGroupProps) {
    const permissionNode = (content: React.ReactNode) => (
        <div style={{width: "50px" }}>
            {content}
        </div>
    )

    return (
        <Flex row align={isHeader ? "center" : "start"} fullWidth justify="between">
            {title}
            <Flex row gap={5}>
                { permissionNode(create) }
                { permissionNode(read) }
                { permissionNode(update) }
                { permissionNode(del) }
            </Flex>
        </Flex>
    )
}