import React from "react";
import Icon from "../icons/Icon";
import { ITicket } from "../../types/ApiTypes";
import Typography from "../text/Typography";

export default function TicketMessagesCount({ticket, wrapperClass}: {ticket: ITicket, wrapperClass?: string}) {
    
    const messages = ticket.messages.length;
    const hasMessages = !!messages;

    return (
        <div className={wrapperClass} style={{width: "fit-content"}}>
            <div className="d-flex flex-row align-items-center justify-content-start gap-2 position-relative">
                <Icon icon={hasMessages ? "chat" : "chat-dots"} color="primary" size={25} />
                {
                    hasMessages && (
                        <div 
                            className="bottom-0 text-center position-absolute start-100 translate-middle d-flex align-items-center justify-content-center" 
                            style={{
                                borderRadius: "50px", 
                                width: "16px", 
                                height: "16px", 
                                backgroundColor: "var(--primary)"
                            }}
                        >
                            <Typography size={9} basedOnThisBackground="primary" >{ messages }</Typography>
                        </div>

                    )
                }
            </div>
        </div>
    )
}