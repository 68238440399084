import React from "react";
import { ITicket, TicketOpeningReason, TicketSource, TicketType } from "../../../types/ApiTypes";
import Flex from "../../container/Flex";
import Typography from "../../text/Typography";
import WithLabel from "../../text/WithLabel";

export default function TicketOpeningReasonBanner({ticket}: {ticket: ITicket}) {
    const getText = () => {
        switch (ticket.openedBecause) {
            case TicketOpeningReason.SentByClientContactMail: return "Mail von Mandantenkontakt";
            case TicketOpeningReason.SentByClientMail: return "Mail von Mandant";
        } 
    }

    if (ticket.openedBecause === undefined) return null;
    if (ticket.type !== TicketType.ExternalTicket) return null;

    return (
        <WithLabel gap={0} label="Eröffnet durch">
            <Typography>{getText()}</Typography>
        </WithLabel>
    )

}