import React from "react";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import usePassword from "../../hooks/usePassword";
import CopyToClipboardButton from "../buttons/CopyToClipboardButton";
import Icon from "../icons/Icon";

export default function SuggestPassword() {
    const { createRandomSecurePassword } = usePassword();
    const [securePw, setSecurePw] = React.useState<string>(createRandomSecurePassword());

    const generatePassword = () => setSecurePw(createRandomSecurePassword());


    return (
        <Flex>
            <Typography color="bright" bold size={14} > 
                Vorschlag
            </Typography>
            <Flex row fullWidth gap={3}>
                <Icon onClick={generatePassword} icon="arrow-clockwise" />
                <Typography color="bright" bold size={14} > 
                    { securePw || "-----"}
                </Typography>
                {
                    securePw && <CopyToClipboardButton value={securePw} />
                }
            </Flex>
            <em>Bitte speichern Sie Ihre Passwörter an einem sicheren Ort</em>
        </Flex>
    )
}