import React from "react";
import { AbsenceSource, AbsenceType } from "../../../types/ApiTypes";
import Pill from "../../pills/Pill";
import Icon from "../../icons/Icon";

export default function AbsenceTypeBanner({type}: {type: AbsenceType}) {

    const getColor = () => {
        switch (type) {
            case AbsenceType.Vacation: return "#D700FF";
            case AbsenceType.Sick: return "#FFA500";
        }
    }

    const getText = () => {
        switch (type) {
            case AbsenceType.Vacation: return "Urlaub";
            case AbsenceType.Sick: return "Krankheit";
        }
    }

    const getIcon = () => {
        switch (type) {
            case AbsenceType.Vacation: return "airplane";
            case AbsenceType.Sick: return "person-hearts";
        }
    }

    return (
        <Pill color={getColor()} >
            <Icon icon={getIcon()} />
            <span>{getText()}</span>
        </Pill>
    )
}