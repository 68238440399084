import React from "react";
import Flex from "../container/Flex";
import DOMPurify from "dompurify";
import { generateStyle } from "../../hooks/useAttributes";

export interface IRichTextViewerProps {
    content?: string,
    canShowMore?: boolean,
    collapsedSize?: number
}

export default function RichTextViewer({content, collapsedSize = 150, canShowMore}: IRichTextViewerProps) {
    const [expanded, setExpanded] = React.useState<boolean>(!canShowMore);

    const style = generateStyle({
        name: "maxHeight", 
        applyCondition: canShowMore && !expanded,
        value: collapsedSize,
        unit: "px"
    }, {
        name: "transition",
        value: "all 0.3s ease-in-out"
    }, {
        name: "overflowY",
        value: "auto"
    });

    return (
        <Flex className="w-100 h-100" style={style}>
            {
                content
                ? <div className="w-100 h-100" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content)}} />
                : <em>Kein Inhalt</em>
            }
        </Flex>
    )
}