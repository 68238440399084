import React, { useEffect } from "react";
import { IDomain, ITenant } from "../../types/ApiTypes";
import Table from "../tables/Table";
import DomainListItem from "./DomainListItem";
import WithPermissions from "../permissions/WithPermissions";

export default function DomainList({domains}: {domains: IDomain[] | null}) {
    if (!domains || !domains.length) return <span>Keine Domains gefunden.</span>

    return (
        <WithPermissions permissions={[ "domains.tenant.read" ]}>
            <Table 
                border 
                className="mt-3" 
                headers={[{label: "Domain"}, {label: "Verifiziert"}, {label: ""}]}
                items={domains}
                renderItem={(d: IDomain) => <DomainListItem key={d._id} domain={d} />}
            />
        </WithPermissions>
    )
}