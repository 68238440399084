import { Field } from "formik";
import React from "react";
import "./ColorPicker.css";

export interface IColorPickerProps {
    name: string
}

export default function ColorPicker({name}: IColorPickerProps) {
    return (
        <Field name={name} type="color" className="form-control color-picker" />
    )
}