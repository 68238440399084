import React from "react"; 
import { teamsDelete, tenantsDelete, usersDelete } from "../../api/Api";
import { AppColor } from "../../app/AppStyles";
import useApi from "../../hooks/useApi";
import { ITeamDeleteRequest } from "../../api/ApiRequests";
import Button from "../buttons/Button";
import WithPermissions from "../permissions/WithPermissions";

interface IDeleteTeamProps extends ITeamDeleteRequest {
    afterDelete: () => void
}

export default function TeamDeleteButton({teamId, afterDelete}: IDeleteTeamProps) {
    const callApi = useApi();

    const deleteTeam = async () => {
        const res = await callApi(teamsDelete({teamId: teamId}), true);
        if (res) afterDelete();
    }

    return (
        <WithPermissions permissions={["teams.all.delete"]}>
            <Button type="button" color="error" icon="trash" loadingText="Wird gelöscht..." text="Team löschen" onClick={deleteTeam} /> 
        </WithPermissions>
    )
}