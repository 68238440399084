import React, { PropsWithChildren } from "react";
import { AppColor } from "../../app/AppStyles";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";
import { IButtonProps } from "./Button";
import "./LabelButton.css";

export default function LabelButton({loading, text, disabled, disabledText, type = "button", loadingText, className, onClick, color, children, icon}: IButtonProps) {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (loading === undefined) return;
        setIsLoading(loading);
    }, [loading]);
    
    const textClass = generateClassName("text-nowrap", {value: loading, onTrue: "ms-2"});
    const textStyle = generateStyle({
        name: "color",
        value: color,
        standard: "primaryAccent",
        isVar: true
    })

    const buttonStyle = generateStyle({
        name: "textDecorationColor",
        value: color,
        standard: "primaryAccent",
        isVar: true
    });

    const clickHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            setIsLoading(true);

            if (!onClick) return;
            await onClick(e);
        }
        catch { }
        finally {
            setIsLoading(false);
        }
    }

    const buttonClass = generateClassName(className, "m-0 p-0 label-button position-relative");

    return (
        <button onClick={clickHandler} type={type} className={buttonClass} disabled={loading || disabled} style={buttonStyle} >
            <div className="label-button-content position-relative gap-1 d-flex flex-row align-items-center justify-content-center" >
                {
                    isLoading 
                    ? <LoadingSpinner size={15} />
                    : icon && <Icon size={12} icon={icon} color={color} />
                }
                <span className={textClass} style={textStyle}>{isLoading ? loadingText : (disabled ? disabledText || text : text)}</span>
            </div>
        </button>
    )
}