import React from "react";
import { AppColor } from "../../app/AppStyles";
import Icon from "../icons/Icon";
import "./CopyableDnsEntry.css";

interface ICopyableDnsEntryProps {
    value: string
}

export default function CopyableDnsEntry({value}: ICopyableDnsEntryProps) {
    
    const [copied, setCopied] = React.useState<boolean>(false);
    const [cooldownTimer, setCooldownTimer] = React.useState<any>();

    const copyToClipboard = () => {
        clearTimeout(cooldownTimer);
        navigator.clipboard.writeText(value);
        setCopied(true);
        const x = setTimeout(() => setCopied(false), 1000);
        setCooldownTimer(x);
    }

    return (
        <div className="d-flex flex-row align-items-center">
            <span>{value}</span>
            <button disabled={copied} onClick={copyToClipboard} className="ms-3 d-flex flex-row align-items-center copy-dns-entry-btn position-relative">
                <div className="copy-dns-entry-btn-background w-100 h-100 position-absolute top-0 start-0" />
                { 
                    copied && <Icon size={12} className="m-0 p-0" icon="check" color="success" />
                }
                <span>
                    {
                        copied 
                        ? "Kopiert"
                        : "Kopieren"
                    }
                </span>
            </button>
        </div>
    )
}