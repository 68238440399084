import React from "react";
import { TicketEscalationAction } from "../../types/ApiTypes";
import Pill from "../pills/Pill";
import Icon from "../icons/Icon";
import { AppColor } from "../../app/AppStyles";
import Flex from "../container/Flex";
import "./TicketEscalationActionChip.css";
import { generateClassName } from "../../hooks/useAttributes";

export interface ITicketEscalationActionChipProps {
    action: TicketEscalationAction,
    isActive: boolean,
    readOnly: boolean,
    canBeSelected: boolean,
    saveAction?: (action: TicketEscalationAction) => void,
    deleteAction?: (action: TicketEscalationAction) => void
}

export default function TicketEscalationActionChip({action, canBeSelected, isActive, deleteAction, readOnly, saveAction}: ITicketEscalationActionChipProps) {

    const getText = () => {
        switch (action) {
            case TicketEscalationAction.EscalateTicket: return "Zuweisung an Vorgesetzten";
            case TicketEscalationAction.MailToSupervisor: return "Vorgesetzten benachrichtigen";
            case TicketEscalationAction.MailToClient: return "Mandanten benachrichtigen";
            case TicketEscalationAction.CloseTicket: return "Ticket schließen";
        }
    }

    const className = generateClassName({
        base: "ticket-escalation-chip-",
        value: isActive,
        onTrue: "active",
        standard: "inactive"
    }, {
        value: readOnly,
        onTrue: "ticket-escalation-chip-read-only"
    });

    if (!canBeSelected) return null;
    
    return (
        <div style={{backgroundColor: "#EEEEEE", borderRadius: "var(--borderRadius)"}}>
            <Pill
                rounded
                className={className}
                onClick={
                    readOnly
                    ? undefined
                    : () => {
                        if (readOnly) return;
                        else if (isActive && deleteAction) deleteAction(action);
                        else if (saveAction) saveAction(action);
                    }
                }
            >
                <Flex gap={2} row>
                    <Icon 
                        hexColor={isActive ? "#00FF00" : "grey"}
                        icon={isActive ? "check" : "plus"} 
                    />
                    <span>{getText()}</span>
                </Flex>
            </Pill>
        </div>
    )
}