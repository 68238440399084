import Stripe from "stripe"
import { getEnumAsArray } from "../util/util"

export interface IBillingInterval {
    key: string,
    value: Stripe.Price.Recurring.Interval
}

export default function useBillingInterval() {


    const billingIntervals: Array<IBillingInterval> = [
        {
            key: "Täglich",
            value: "day"
        },
        {
            key: "Wöchentlich",
            value: "week"
        },
        {
            key: "Monatlich",
            value: "month"
        },
        {
            key: "Jährlich",
            value: "year"
        }
    ];

    const getIntervalFromStripeBillingInterval = (interval: Stripe.Price.Recurring.Interval): IBillingInterval | undefined => billingIntervals.find(b => b.value === interval);

    return {
        billingIntervals,
        getIntervalFromStripeBillingInterval
    }
}