import React from "react";
import { IInvoice } from "../../types/ApiTypes";
import Pill from "../pills/Pill";

export default function InvoiceStatus({invoice}: {invoice: IInvoice}) {
    const getColor = (): string => {
        switch (invoice.status) {
            case "draft": return "#E4E7F4";
            case "open": return "#F5DD5B";
            case "paid": return "#29D69C";
            case "uncollectible": return "#F54A4A";
            case "void": return "#F5C4CF";
        }
    }

    const getText = (): string => {
        switch (invoice.status) {
            case "draft": return "In Bearbeitung";
            case "open": return "Offen";
            case "paid": return "Bezahlt";
            case "uncollectible": return "Zahlungsprobleme";
            case "void": return "Nicht verfügbar";
        }
    }

    return (
        <Pill color={getColor()} text={getText()} />
    )
}