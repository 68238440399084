import React from "react";
import "./SearchBox.css";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import { generateClassName } from "../../hooks/useAttributes";

export interface ISearchBoxProps {
    delay?: number,
    placeholder?: string,
    onChange: (value: string) => void,
    initialValue?: string,
    className?: string,
    noShadow?: boolean
}

export default function SearchBox({noShadow, onChange, delay, initialValue, className, placeholder}: ISearchBoxProps) {
    const [value, setValue] = React.useState<string>(initialValue || "");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [hasFocus, setHasFocus] = React.useState<boolean>(false);

    const timeoutRef = React.useRef<any>();

    React.useEffect(() => {
        if (!initialValue) return;
        if (value) return;
        setValue(initialValue || "");
    }, [initialValue]);

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        if (delay) {
            clearTimeout(timeoutRef.current);
            setLoading(true);
            timeoutRef.current = setTimeout(() => {
                setLoading(false);
                onChange(e.target.value);
            }, delay);
            return;
        }
        else onChange(e.target.value);
    }

    const realClass = generateClassName(className, "ms-auto form-control search-box", {
        value: noShadow,
        standard: "search-box-shadow"
    }, {
        value: hasFocus,
        onTrue: "search-box-focus"
    });

    const containerClass = generateClassName(className, "position-relative");

    return (
        <Flex className={containerClass}>
            <input 
                className={realClass} 
                value={value} 
                onFocus={() => setHasFocus(true)} 
                onBlur={() => setHasFocus(false)} 
                onChange={changeHandler} 
                placeholder={placeholder || "Suche..."} 
            />
            <Icon 
                loading={loading} 
                icon={value ? "x" : "search"} 
                onClick={
                    value 
                    ? () => { 
                        setValue("");
                        onChange(""); 
                    } 
                    : undefined
                } 
                color="muted" 
                wrapperClass="position-absolute top-50 end-0 translate-middle"
            /> 
        </Flex>
    )
}