import React from "react";
import "./TypeSelect.css";
import TypeSelectItem from "./TypeSelectItem";
import Flex from "../container/Flex";
import { AppColor } from "../../app/AppStyles";
import Typography from "../text/Typography";

export interface ITypeSelection {
    color?: AppColor | string,
    text: string,
    data: string
}

export interface ITypeSelectProps {
    options: Array<ITypeSelection>,
    value: string,
    label?: string,
    saveValue: (val: string) => void
}

export default function TypeSelect({options, label, value, saveValue}: ITypeSelectProps) {
    const [selectedValue, setSelectedValue] = React.useState<string>(value);    

    React.useEffect(() => {
        if (!options || !options.length) return;
        if (!value) setSelectedValue(options[0].data);

        const existingItem = options.find(o => o.data === value);

        if (existingItem) setSelectedValue(existingItem.data);
        else setSelectedValue(options[0].data);
    }, [value, options]);

    const onItemClick = (val: string) => {
        setSelectedValue(val);
        saveValue(val);
    }

    if (!options || !options.length) return null;

    const content = (
        <Flex row>
            {
                options.map(o => <TypeSelectItem option={o} value={selectedValue} saveValue={onItemClick} />)
            }
        </Flex>
    );

    if (!label) return content;

    return (
        <Flex>
            <Typography color="primary">{label}</Typography>
            { content }
        </Flex>
    )
}