import React from "react";
import { ITypeSelection } from "./TypeSelect";
import "./TypeSelectItem.css";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { getTextColorFromBackground, parseVarColorToHex } from "../../util/util";

export interface ITypeSelectItemProps {
    option: ITypeSelection,
    value: string,
    saveValue: (value: string) => void
}

export default function TypeSelectItem({option, saveValue, value}: ITypeSelectItemProps) {

    const [isActive, setIsActive] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!option) {
            setIsActive(false);
            return;
        }

        if (!value) {
            setIsActive(false);
            return;
        }

        setIsActive(option.data === value);
    }, [option, value]);
    
    if (!option) return null;

    const className = generateClassName("type-select-item position-relative ps-3 pe-3", {
        value: isActive,
        onTrue: "active",
        standard: "inactive",
        base: "type-select-item-"
    });

    return (
        <div className={className} onClick={() => saveValue(option.data)}>
            <div 
                className="w-100 h-100 type-select-item-background position-absolute top-0 start-0" 
                style={generateStyle({
                    name: "backgroundColor",
                    value: parseVarColorToHex(option.color, "primary")
                })}
            />
            <div className="position-relative type-select-item-text" style={generateStyle({
                name: "color",
                value: getTextColorFromBackground(option.color)
            })}>
                { option.text }
            </div>
        </div>
    )
}