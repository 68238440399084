import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import PageLoader from "../loader/PageLoader";
import { Status } from "../../config/Status";
import { IInvoice } from "../../types/ApiTypes";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";
import { formatCurrency } from "../../util/formatter";
import LabelButton from "../buttons/LabelButton";
import InvoiceStatus from "./InvoiceStatus";

export default function InvoiceItem({invoice}: {invoice: IInvoice}) {

    if (!invoice) return null;

    return (
        <TableRow>
            <TableCell date={invoice.periodStart} />
            <TableCell date={invoice.periodEnd} />
            <TableCell content={`${formatCurrency(invoice.amount / 100, invoice.currency)}`} />
            <TableCell>
                <InvoiceStatus invoice={invoice} />
            </TableCell>
            <TableCell>
                {
                    invoice.url
                    ? <LabelButton onClick={async () => window.open(invoice.url)} text={invoice.status === "paid" ? "Herunterladen" : "Bezahlen"} />
                    : <span>Kein PDF verfügbar</span>
                }
            </TableCell>
        </TableRow>
    )
}