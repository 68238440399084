import React from "react";
import { AppColor } from "../../app/AppStyles";
import Icon from "./Icon";

interface ICheckmarkIconProps {
    value: boolean,
    trueLabel?: string,
    falseLabel?: string
}

export default function CheckmarkIcon({value, trueLabel, falseLabel}: ICheckmarkIconProps) {
    return <Icon icon={value ? "check" : "x"} label={value ? trueLabel : falseLabel} size={20} color={value ? "success" : "error"} />
}