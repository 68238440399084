import React from "react";
import { AbsenceSource } from "../../../types/ApiTypes";
import Pill from "../../pills/Pill";
import Icon from "../../icons/Icon";

export default function AbsenceSourceBanner({source}: {source: AbsenceSource}) {

    const getColor = () => {
        switch (source) {
            case AbsenceSource.PointChamp: return "#FFD700";
            case AbsenceSource.Web: return "#FFA500";
        }
    }

    const getText = () => {
        switch (source) {
            case AbsenceSource.PointChamp: return "PointChamp";
            case AbsenceSource.Web: return "Web";
        }
    }

    const getIcon = () => {
        switch (source) {
            case AbsenceSource.PointChamp: return "star";
            case AbsenceSource.Web: return "web";
        }
    }

    return (
        <Pill color={getColor()} >
            <Icon icon={getIcon()} />
            <span>{getText()}</span>
        </Pill>
    )
}