export enum Cookies {
    Session = "session"
}

export const getCookie = (cookie: Cookies) => {
    const value = `; ${document.cookie}`;

    const parts = value.split(`; ${cookie}=`);
    if (!parts || parts.length !== 2) return null;
    return parts.pop()?.split(';').shift();
}

export const deleteCookie = (cookie: Cookies) => {
    const storedCookie = getCookie(cookie);

    if (!storedCookie) return;
    
    const date = new Date(Date.parse("Thu, 01 Jan 1970 00:00:01 GMT"));
    document.cookie = `${cookie}=; expires=${date.toUTCString()}; domain=${window.location.hostname}; path=/`;
}

export const setCookie = (cookie: string, value: any, days: number = 1) => {
    if (!value) return;
    const date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    document.cookie = `${cookie}=${value}; expires=${date.toUTCString()}; path=/`;
}