import React, { Fragment, PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function UpdateStatesOnRouteChange({children}: PropsWithChildren) {

    const location = useLocation();
    
    useEffect(() => {
        const main = document.getElementById("main");
        if(main) main.scrollTo(0,0);
    }, [location]);

    return (
        <>
            {
                children
            }
        </>
    )
}