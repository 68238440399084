import { generateClassName } from "../../hooks/useAttributes";
import React, { useState, KeyboardEvent } from "react";
import "./OtpField.css";

interface IOtpFieldProps {
    readOnly: boolean,
    hasFocusAtStart?: boolean,
    savePastedValue: (val: Array<string>) => void,
    saveValue: (index: number, value: string | null) => void,
    actionAfterSave?: () => void,
    value: string,
    index: number,
    error: boolean
}

export default function OtpField({index, hasFocusAtStart, value, savePastedValue, saveValue, actionAfterSave, readOnly, error}: IOtpFieldProps) {

    const [hasFocus, setHasFocus] = useState(false);

    const getId = (id: number): string => `unique-otp-field-${id}`;

    const activeOtpFieldWithId = (i: number) => {
        const id = getId(i);
        const element = document.getElementById(id);
        if (!!element) element.focus();
    }

    const handlePaste = (e: any) => {
        try {
            if (!e) return;
    
            e.preventDefault();
    
            const paste = (e.clipboardData || window.Clipboard).getData("text");

            if (!paste) return;
            if (paste.length !== 6) return;

            const parts = paste.split("");

            const numericParts: Array<string> = parts.map((p: string) => {
                const num = +p;
                if (isNaN(num)) return null;
                return `${num}`;
            }).filter((p: string | null) => p !== null);

            if (!numericParts || !numericParts.length) return;
            if (numericParts.length !== 6) return;

            savePastedValue(numericParts);
        }
        catch { }
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        
        if (e.ctrlKey) return;
        
        e.preventDefault();
        const value = e.key.trim();
        

        const isDeleteKey = !value || value === "Backspace" || value === "Delete";
        
        if (isDeleteKey) {
            saveValue(index, null);
            activeOtpFieldWithId(index - 1);
            return;
        }

        const isNumber = !isNaN(+value);

        if (!isNumber) {
            return;
        }

        saveValue(index, value);
        activeOtpFieldWithId(index + 1);

        if (actionAfterSave) {
            actionAfterSave();
            setHasFocus(false);
        }
    }

    const className = generateClassName("otp-field", { value: error, onTrue: "otp-field-error" }, { value: hasFocus, onTrue: "otp-field-focus" });

    return (
        <input 
            autoComplete="0" 
            autoCorrect="0" 
            autoFocus={hasFocusAtStart} 
            id={getId(index)} 
            onFocus={() => setHasFocus(true)} 
            onBlur={() => setHasFocus(false)} 
            value={value} 
            disabled={readOnly} 
            type="text" 
            className={className} 
            onChange={() => {}} 
            onKeyDown={handleKeyDown} 
            onPaste={handlePaste}
        />
    )
}