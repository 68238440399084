import React from "react";

export default function ResponsiveText({content}: {content: string}) {
    const [height, setHeight] = React.useState<number>(0);
    const inputRef = React.useRef<HTMLTextAreaElement>(null);

    React.useEffect(() => {
        if (!inputRef || !inputRef.current) return;
        setHeight(inputRef.current.scrollHeight);
    }, [inputRef, content]);
    
    return (
        <textarea 
            className="w-100" 
            style={{
                border: "none",  
                backgroundColor: "transparent", 
                height: `${height}px`,
                resize: "none",
                userSelect: "none"
            }} 
            disabled 
            value={content} 
            ref={inputRef} 
        />
    )
}