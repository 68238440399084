import React from "react";
import { useAppSelector } from "../../hooks/reduxHooks";
import useClientResponsibility from "../../hooks/useClientResponsibility";
import PageLoader from "../loader/PageLoader";
import Table from "../tables/Table";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";
import WithPermissions from "../permissions/WithPermissions";

export default function RawClientResponsibilityList() {
    
    const {
        loading,
        rawResponsibilities,
    } = useClientResponsibility();

    return (
        <WithPermissions permissions={["clientResponsibilities.all.read"]}>
            {
                loading
                ? <PageLoader />
                : (
                    !rawResponsibilities || !rawResponsibilities.length
                    ? <span>Keine Daten</span>
                    : (
                        <Table 
                            headers={[
                                {label: "Mandantennummber"}, 
                                {label: "Mandanten-ID"}, 
                                {label: "Name"}, 
                                {label: "Mandatsstatus"}, 
                                {label: "Zuständigkeits-ID"}, 
                                {label: "Zuständigkeit"}, 
                                {label: "Mitarbeiter-ID"}, 
                                {label: "Mitarbeiter"}, 
                                {label: "Mitarbeiter-Status"}, 
                                {label: "Mitarbeiternummer"}
                            ]}
                            items={rawResponsibilities}
                            renderItem={r => (
                                <TableRow key={r._id}>
                                    <TableCell content={r.client_number} />
                                    <TableCell content={r.client_id} />
                                    <TableCell content={r.client_name} />
                                    <TableCell content={r.client_status} />
                                    <TableCell content={r.area_of_responsibility_id} />
                                    <TableCell content={r.area_of_responsibility_name} />
                                    <TableCell content={r.employee_id} />
                                    <TableCell content={r.employee_display_name} />
                                    <TableCell content={r.employee_status} />
                                    <TableCell content={r.employee_number} />
                                </TableRow>
                            )}
                        />
                    )
                )
            }
        </WithPermissions>
    )
}