import React from "react";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import "./SortDirectionToggle.css";

export enum SortDirection {
    Ascending = "asc",
    Descending = "desc"
}

export interface ISortDirectionToggle {
    direction?: SortDirection;
    saveDirection: (direction: SortDirection) => void;
    size?: number
}

export default function SortDirectionToggle({direction, saveDirection, size = 14}: ISortDirectionToggle) {

    const toggleDirection = () => {
        if (!direction || direction === SortDirection.Ascending) return saveDirection(SortDirection.Descending);
        return saveDirection(SortDirection.Ascending)
    }

    return (
        <Flex className="sort-direction-switcher" gap={0} onClick={toggleDirection}>
            <Icon icon="chevron-up" size={14} color={direction === SortDirection.Ascending ? "primary" : "muted"} />
            <Icon icon="chevron-down" style={{marginTop: `-7px`}} size={size} color={direction === SortDirection.Descending ? "primary" : "muted"} />
        </Flex>
    )
}