import Stripe from "stripe"
import { getEnumAsArray } from "../util/util"
import { CouponType } from "../types/ApiTypes";

export interface ICouponDuration {
    key: string,
    value: Stripe.Coupon.Duration
}

export interface ICouponType {
    key: string,
    value: CouponType
}

export default function useCoupon() {
    const couponDurations: Array<ICouponDuration> = [
        {
            key: "Endlos",
            value: "forever"
        },
        {
            key: "Einmalig",
            value: "once"
        },
        {
            key: "Wiederholend",
            value: "repeating"
        }
    ];

    const getDurationFromStripeDuration = (duration: Stripe.Coupon.Duration): ICouponDuration | undefined => couponDurations.find(b => b.value === duration);

    const couponTypes: Array<ICouponType> = [
        {
            key: "Absoluter Betrag",
            value: CouponType.AmountOff
        },
        {
            key: "Prozentual",
            value: CouponType.PercentOff
        }
    ]
    
    return {
        couponDurations,
        couponTypes,
        getDurationFromStripeDuration
    }
}