import * as countryCodes from "iso-3166-1-alpha-2";

export interface IIsoCountryCode {
    code: string,
    country: string,
    codeToUpper: string
}

export default function useCountryCodes() {
    
    const getCountryCodes = (): Array<IIsoCountryCode> => {
        const data = countryCodes.getData();
        const result: Array<IIsoCountryCode> = [];

        if (!data) return result;

        for (const [key, value] of Object.entries(data)) {
            if (!key || !value || typeof key !== "string") continue;

            result.push({
                code: key,
                codeToUpper: key.toUpperCase(),
                country: value
            });
        }

        return result;
    }

    return getCountryCodes();
}