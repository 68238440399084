import React from "react";
import { ITicket, TicketState, TicketType } from "../../../types/ApiTypes";
import Pill from "../../pills/Pill";
import "./TicketStateBanner.css";
import useTicketUtil from "../../../hooks/useTicketUtil";
import Typography from "../../text/Typography";

export default function TicketTypeBanner({ticket}: {ticket: ITicket}) {

    const {
        getTicketTypeName,
        getTicketTypeColor
    } = useTicketUtil();

    if (!ticket) return null;

    return <Typography size={12}>{getTicketTypeName(ticket.type)}</Typography>
}