export interface IPasswordValidationResult {
    hasMinLength: boolean,
    hasUpperCase: boolean,
    hasLowerCase: boolean,
    hasNumber: boolean,
    hasSpecialCharacter: boolean,
}

export default function usePassword() {

    const createRandomSecurePassword = (): string => {
        const specialChars = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
        const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
        const numberChars = "0123456789";

        const length = 16;

        let password = "";

        const appendCharFromCollection = (collection: string) => {
            password += collection[Math.floor(Math.random() * collection.length)];
        }

        for (let i = 0; i < length; i++) {
            const random = Math.floor(Math.random() * 4);

            switch (random) {
                case 0:
                    appendCharFromCollection(specialChars);
                    break;
                case 1:
                    appendCharFromCollection(upperCaseChars);
                    break;
                case 2:
                    appendCharFromCollection(lowerCaseChars);
                    break;
                case 3:
                    appendCharFromCollection(numberChars);
                    break;
            }
        }

        appendCharFromCollection(specialChars);
        appendCharFromCollection(upperCaseChars);
        appendCharFromCollection(lowerCaseChars);
        appendCharFromCollection(numberChars);

        return password;
    }

    const isSecurePassword = (pw: string) => {
        const result = getValidationResult(pw);

        if (!result.hasMinLength) return false;
        if (!result.hasUpperCase) return false;
        if (!result.hasLowerCase) return false;
        if (!result.hasNumber) return false;
        if (!result.hasSpecialCharacter) return false;

        return true;
    }

    const getValidationResult = (pw: string): IPasswordValidationResult => {
        const result: IPasswordValidationResult = {
            hasLowerCase: false,
            hasMinLength: false,
            hasNumber: false,
            hasSpecialCharacter: false,
            hasUpperCase: false
        }

        if (pw.length >= 8) result.hasMinLength = true;
        if (pw.match(/[a-z]/)) result.hasLowerCase = true;
        if (pw.match(/[A-Z]/)) result.hasUpperCase = true;
        if (pw.match(/[0-9]/)) result.hasNumber = true;
        if (pw.match(/[!@#$%^&*]/)) result.hasSpecialCharacter = true;

        return result;
    }

    return {
        isSecurePassword,
        createRandomSecurePassword,
        getValidationResult
    }
}