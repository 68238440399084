import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalType } from "../../config/ModalTypes";

export interface IModalActionPayload {
    text: string, 
    type: ModalType
}

interface IModalSlice {
    id: number,
    visible?: boolean,
    text: string,
    type: ModalType
}

export const ModalSlice = createSlice({
    name: "modal",
    initialState: {
        id: 0,
        visible: false,
        text: "",
        type: ModalType.Information
    } as IModalSlice,
    reducers: {
        setModal: (state, action: PayloadAction<false | IModalActionPayload>) => {
            const payload = action.payload;

            state.id += 1;

            if (typeof (payload) === "boolean") {
                state.visible = false
                return;
            }

            state.visible = true;
            state.type = payload.type;
            state.text = payload.text;
        }
    }
});

export const { setModal } = ModalSlice.actions;

export default ModalSlice.reducer;