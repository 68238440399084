import { IFileMetadata } from "../types/ApiTypes";

export default function useDownloads() {
    const downloadFile = (metadata: IFileMetadata, content: string, isRawData?: boolean) => {
        const link = document.createElement('a');

        if (isRawData) link.href = `data:${content}`;
        else link.href = `data:${metadata.type};base64,${content}`;

        link.download = metadata.name;

        document.body.appendChild(link);
        
        link.click();
    
        if (!link.parentNode) return;

        link.parentNode.removeChild(link);
    }

    return {
        downloadFile
    }
}