import React from "react";
import Stripe from "stripe";
import Icon from "../icons/Icon";

export default function PaymentMethodIcon({paymentMethod}: {paymentMethod: Stripe.PaymentMethod}) {
    if (!paymentMethod) return null;

    const getIcon = (): string => {
        if (paymentMethod.card) return "credit-card";
        return "";
    }

    const getLabel = (): string => {
        if (paymentMethod.card) return paymentMethod.card.brand.toUpperCase();
        return "";
    }

    return <Icon icon={getIcon()} label={getLabel()} /> 
}