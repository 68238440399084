import React from "react";
import useTicketUtil from "../../../hooks/useTicketUtil";
import { ITicket, TicketType } from "../../../types/ApiTypes";
import Pill from "../../pills/Pill";
import Flex from "../../container/Flex";
import Typography from "../../text/Typography";
import WithLabel from "../../text/WithLabel";

export default function TicketOriginBanner({ticket, colorOnly = false}: {colorOnly?: boolean, ticket: ITicket}) {
    const {
        getTicketTypeName
    } = useTicketUtil();

    if (!ticket || ticket.type === null || ticket.type === undefined) return null;
    
    const getColorByType = () => {
        switch (ticket.type) {
            case TicketType.InternalTicket: return "#F0A0A0";
            case TicketType.TicketByClient: return "#A0A0F0";
            case TicketType.TicketToClient: return "#A0F0A0";
        }
    }
    
    if (colorOnly) return (
        <Pill color={getColorByType()}>
            <span className="text-nowrap">{getTicketTypeName(ticket.type)}</span>
        </Pill>
    )

    return (
        <WithLabel label="Art" gap={0}>
            <Typography>{getTicketTypeName(ticket.type)}</Typography>
        </WithLabel>
    )
}