import React from "react";
import { AppColor } from "../../app/AppStyles";
import LoadingSpinner from "./LoadingSpinner";

export default function PageLoader() {
    return (
        <div className="w-100 h-100 align-items-center justify-content-center d-flex">
            <div 
                style={{
                    borderBottomColor: "var(--secondary)",
                    borderTopColor: "var(--secondary)",
                    borderLeftColor: "var(--secondary)",
                    width: "100px",
                    height: "100px",
                    borderWidth: "10px"
                }}
                className="spinner spinner-border loading-spinner"
            />
        </div>
    )
}