import React from "react";
import Flex from "../container/Flex";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import DatevConnectorSettings from "./settings/DatevConnectorSettings";
import PointChampConnectorSettings from "./settings/PointChampConnectorSettings";
import WithPermissions from "../permissions/WithPermissions";

export enum ConnectorSettingsTab {
    Datev = "datev",
    PointChamp = "pointchamp",
}

export default function ConnectorSettings() {

    const [tab, setTab] = React.useState<ConnectorSettingsTab>(ConnectorSettingsTab.Datev);

    return (
        <WithPermissions permissions={[ "interfaces.datev.read", "interfaces.pointchamp.read"]}>
            <Flex gap={4} fullWidth>
                <TabSwitcher
                    size="small"
                    tabs={[
                        {
                            label: "Datev",
                            data: ConnectorSettingsTab.Datev,
                            permissions: [ "interfaces.datev.read" ]
                        },
                        {
                            label: "PointChamp",
                            data: ConnectorSettingsTab.PointChamp,
                            permissions: [ "interfaces.pointchamp.read" ]
                        },
                    ]}
                    tabQueryParamKey="connector"
                    saveActiveTab={(tab) => setTab(tab as ConnectorSettingsTab)}
                />
                {
                    (() => {
                        switch (tab) {
                            case ConnectorSettingsTab.Datev: return <DatevConnectorSettings />
                            case ConnectorSettingsTab.PointChamp: return <PointChampConnectorSettings />
                        }
                    })()
                }
            </Flex>
        </WithPermissions>
    )
}