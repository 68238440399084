import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isErrorApiResponse, usersGetAll, versionGet } from "../../api/Api";
import { Status } from "../../config/Status";
import { IUser, IVersion } from "../../types/ApiTypes";
import { IStatefulSlice } from "../../types/ReduxTypes";

export const getVersion = createAsyncThunk<IVersion, undefined, {rejectValue: any}>("version/getVersion", async (arg, {signal, rejectWithValue}) => {
    try {
        const version = await versionGet(signal);

        if (isErrorApiResponse(version)) rejectWithValue(null);

        return version.data;
    }
    catch (err: any) {
        return rejectWithValue(err.response.data);
    }
});

export interface IVersionSlice extends IStatefulSlice {
    content: IVersion | null
}

export const VersionSlice = createSlice({
    name: "version",
    initialState: {
        status: Status.Idle
    } as IVersionSlice,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getVersion.pending, (state, action) => {
            state.status = Status.Loading;
        })
        .addCase(getVersion.fulfilled, (state, action) => {
            state.status = Status.Success;
            
            const payload = action.payload;

            if (!payload) {
                state.status = Status.Error;
                return;
            }

            state.content = payload;
        })
        .addCase(getVersion.rejected, (state, action) => {
            state.status = Status.Error;
            state.error = action.payload || action.error;
        });
    }
});

export default VersionSlice.reducer;